import { useEffect, useState } from "react";
import { FormControlLabel, Checkbox, Button } from "@mui/material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { EventType, INTAKE_STEP } from "../../utility/constants";
import { FormValue } from "../../domain/FormValue";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { updateProgress } from "../../utility/metricsUtils";
import { constructQueryParams } from "../../utility/stringUtils";
import useHttp from "../../hooks/use-http/use-http";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { useIntl } from "react-intl";
import "./ConsentPage.css";
import eMed_Consent_png from "../../resources/images/eMed_Consent.svg";
import eMed_Consent_step1_svg from "../../resources/images/eMed_Consent_Step1.svg";
import eMed_Consent_step2_svg from "../../resources/images/eMed_Consent_Step2.svg";
import eMed_Consent_step3_svg from "../../resources/images/eMed_Consent_Step3.svg";

export type ConsentStatus = {
	consent?: boolean;
	formValues?: FormValue[];
};

const ConsentPage = () => {
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const { state } = useLocation();
	const { consultId, zipCode } = useParams();
	const intl = useIntl();
	const navigate = useNavigate();
	const { sendRequest } = useHttp();

	const [formValues, setFormValues] = useState<FormValue[] | undefined>([]);
	const [consent, setConsent] = useState<boolean | undefined>(false);
	const [redirectUri, setRedirectUri] = useState<string>("");
	const [procedure, setProcedure] = useState<string>("");
	const [queryParams] = useSearchParams();
	const [mode, setMode] = useState("");
	const [isMobile, setIsMobile] = useState<boolean | null>(null);
	const isBrowser = typeof window !== "undefined";

	const consentPDF = "../../../documents/consent-agreement.pdf";

	const consentText = (
		<label>
			{intl.formatMessage({ id: "consentCheckboxLabel" })}
			<a href={consentPDF} target="_blank" rel="noreferrer">
				{intl.formatMessage({ id: "consentCheckboxLink" })}
			</a>
			*
		</label>
	);

	useEffect(() => {
		// Dependency on state is necessary since it'll contain all formValues when the user presses back and exits
		// the FormViewerPage.
		if (state) {
			const { consent, formValues } = state as ConsentStatus;
			setConsent(consent);
			setFormValues(formValues);
		}
		const redirectUri = queryParams.get("redirect_uri");
		const modeParam = queryParams.get("mode");
		const procedureParam = queryParams.get("procedure");

		if (redirectUri) {
			setRedirectUri(`redirect_uri=${redirectUri}`);
		}
		if (procedureParam) {
			setProcedure(`procedure=${procedureParam}`);
		}
		if (modeParam) {
			setMode(`mode=${modeParam}`);
		}
	}, [state, queryParams, setExitPoint]);

	const navigationHandler = () => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		navigate(`/${zipCode}/consult/${consultId}/start/questionnaire${queryParams}`, {
			state: {
				consent: consent,
				formValues: formValues
			}
		});
	};

	useEffect(() => {
		if (setExitPoint) setExitPoint("consent_page");
		updateProgress(EventType.STEP_CHANGE, consultId, INTAKE_STEP.CONSENT_PAGE, sendRequest);
	}, [consultId, sendRequest, setExitPoint]);

	const handleWindowSizeChange = () => {
		if (isBrowser) {
			if (window.innerWidth <= 700) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		}
	};

	useEffect(() => {
		if (setExitPoint) setExitPoint("consent_page");
		handleWindowSizeChange();
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper enabled={true} id="consent-page-wrapper" consistentPadding={isMobile ? true : false}>
			<h2 className="title-text">{intl.formatMessage({ id: "consentPageTitle" })}</h2>

			<h5 className="subtitle-text">{intl.formatMessage({ id: "consentPageSubtitle" })}</h5>

			{!isMobile ? (
				<img src={eMed_Consent_png} alt="Consent" className="consent-desktop-image" />
			) : (
				<>
					<div>
						<img className="consent-step-image" src={eMed_Consent_step1_svg} alt="Consent1" />
						<span className="consent-step-text">
							<div>{intl.formatMessage({ id: "consentStepText11" })}</div>
							<div>{intl.formatMessage({ id: "consentStepText12" })}</div>
						</span>
					</div>
					<div>
						<img className="consent-step-image" src={eMed_Consent_step2_svg} alt="Consent2" />
						<span className="consent-step-text">
							<div>{intl.formatMessage({ id: "consentStepText21" })}</div>
							<div>{intl.formatMessage({ id: "consentStepText22" })}</div>
						</span>
					</div>
					<div>
						<img className="consent-step-image" src={eMed_Consent_step3_svg} alt="Consent1" />
						<span className="consent-step-text">
							<div>{intl.formatMessage({ id: "consentStepText31" })}</div>
							<div>{intl.formatMessage({ id: "consentStepText32" })}</div>
						</span>
					</div>
				</>
			)}

			<p className="disclaimer-text">{intl.formatMessage({ id: "consentDisclaimer" })}</p>

			<FormControlLabel
				sx={{ my: 1, width: 1 }}
				id="consent"
				control={
					<Checkbox
						id="consent-box"
						required={true}
						checked={consent || false}
						onChange={(event) => {
							setConsent(event.target.checked);
						}}
						inputProps={{
							// @ts-ignore
							"data-testid": "consent-box"
						}}
					/>
				}
				label={consentText}
			/>

			<div className="form-nav-button-container mt-1">
				<div className="form-nav-button-container flex-end mt-1">
					<Button
						id="continue"
						disabled={!consent}
						variant="outlined"
						className="form-nav-button link-button right button-full-width"
						onClick={navigationHandler}
					>
						{intl.formatMessage({ id: "continueButton" })}
					</Button>
				</div>
			</div>
		</PageWrapper>
	);
};

export default ConsentPage;
