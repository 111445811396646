import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from "@mui/material";
import { useMemo, useState } from "react";
import { Button } from "@mui/material";
import { matchRoutes, useLocation, useSearchParams } from "react-router-dom";
import { PATH_PARAMS, PROCEDURE } from "../../utility/constants";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { useIntl } from "react-intl";

import "./logo-banner.css";

const LogoBanner = () => {
	const [open, setOpen] = useState(false);
	const [queryParams] = useSearchParams();
	const location = useLocation();
	const intl = useIntl();
	const { exitPoint } = useExitPoint() as ExitPointContextType;
	const procedureParam = queryParams.get("procedure");
	const hiddenLocations = matchRoutes(
		[{ path: PATH_PARAMS.SUCCESS_PAGE_PATH }, { path: PATH_PARAMS.DENIAL_PAGE_PATH }],
		location
	);

	const isUTIorVPH = useMemo(() => {
		return procedureParam === PROCEDURE.VPH || procedureParam === PROCEDURE.UTI;
	}, [procedureParam]);

	const handleClickOpen = (): void => {
		setOpen(true);
	};

	const handleClose = (): void => {
		setOpen(false);
	};

	const handleExit = (): void => {
		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) {
			window.location.href = `${redirectUri}?result=cancelled&exitPoint=${exitPoint}`;
		} else {
			handleClose();
		}
	};
	return (
		<div className={!hiddenLocations ? "banner-container" : "banner-container center"}>
			<div id="logo-banner" data-testid="logo-banner" className="mr-2"></div>
			{!hiddenLocations && (
				<Button
					variant="outlined"
					className="exit-button right"
					data-testid="exit-button"
					onClick={handleClickOpen}
				>
					{intl.formatMessage({ id: "headerExit" })}
				</Button>
			)}

			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title" className="bold">
					{isUTIorVPH
						? intl.formatMessage({ id: "utiVphExitDialogTitle" })
						: intl.formatMessage({ id: "covidExitDialogTitle" })}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						{intl.formatMessage({ id: "exitDialogContent" })}
					</DialogContentText>
				</DialogContent>
				<DialogActions className="button-container modal-button-container flex-wrap">
					<Button variant="outlined" className="cancel-button left" onClick={() => handleExit()}>
						{isUTIorVPH
							? intl.formatMessage({ id: "exitDialogLink" })
							: intl.formatMessage({ id: "covidExitDialogLink" })}
					</Button>
					<Button className="modal-exit-button form-nav-button right" onClick={handleClose} autoFocus>
						{intl.formatMessage({ id: "exitDialogButton" })}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default LogoBanner;
