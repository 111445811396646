import { useEffect } from "react";
import { Alert } from "@mui/material";
import { useLocation } from "react-router-dom";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { useIntl } from "react-intl";

import "./DenialPage.css";

const DenialPage: React.FC = (): JSX.Element => {
	const { state } = useLocation();
	const intl = useIntl();
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const rejectionCause = state as string[];

	useEffect(() => {
		if (setExitPoint) setExitPoint("rejection_page");
	}, [setExitPoint]);

	return (
		<PageWrapper enabled={true} id="denial-page">
			<h2>{intl.formatMessage({ id: "denialTitle" })}</h2>

			<span className="span-subtitle">{intl.formatMessage({ id: "denialDescription" })}</span>
			<h3>{intl.formatMessage({ id: "denialMotives" })}</h3>
			<div>
				{rejectionCause &&
					rejectionCause.length &&
					rejectionCause.map((cause, idx) => {
						return (
							<Alert severity="error" sx={{ mb: 2 }} key={`${idx}-error`}>
								{cause.split(":")[1] ? cause.split(":")[1].trim() : cause}
							</Alert>
						);
					})}
			</div>
		</PageWrapper>
	);
};

export default DenialPage;
