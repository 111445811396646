import { useCallback, useEffect, useState } from "react";
import {
	Alert,
	Backdrop,
	Button,
	CircularProgress,
	TextField,
	Pagination,
	FormControl,
	InputLabel,
	Select,
	MenuItem
} from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DefaultButton } from "../../components/default-button/default-button";
import { PharmacyService } from "../../api/pharmacy-service";
import { FormValue } from "../../domain/FormValue";
import { Pharmacy } from "../../domain/Pharmacy";
import { SearchPharmacyRequest } from "../../domain/request/SearchPharmacyRequest";
import { FETCH_HEADERS, HTTP_METHODS, CONSTANTS, EventType, INTAKE_STEP, STATES } from "../../utility/constants";
import { format } from "date-fns";
import { QuestionnaireService } from "../../api/questionnaire-service";
import { QuestionnaireAnswersPayload } from "../../domain/QuestionnaireAnswersPayload";
import { FORM_RESPONSE_STATUS } from "../../domain/types/FormElement";
import { FormGeneratorProps } from "../../components/form-components/form-generator/form-generator";
import { ExitPointContextType, useExitPoint } from "../../context/exit-point";
import { updateProgress } from "../../utility/metricsUtils";
import { constructQueryParams } from "../../utility/stringUtils";
import { useIntl } from "react-intl";
import PharmacyOption from "../../components/pharmacy-option/pharmacy-option";
import useHttp, { RequestConfig } from "../../hooks/use-http/use-http";
import PageWrapper from "../../components/page-wrapper/page-wrapper";
import "./PharmacyPickerPage.css";

const PharmacyPickerPage = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const intl = useIntl();
	const { sendRequest } = useHttp();
	const { error, sendRequest: getPharmacies } = useHttp();
	const { consultId, zipCode } = useParams();
	const [queryParams] = useSearchParams();

	const ITEMS_PER_PAGE = 10;
	const isBrowser = typeof window !== "undefined";
	const searchPharmacyUrl = PharmacyService.getSearchPharmacyUrl();
	const modeParam = queryParams.get("mode");

	const [loading, setLoading] = useState<boolean>(false);
	const [pharmacyList, setPharmacyList] = useState<Pharmacy[]>([]);
	const [selectedPharmacy, setSelectedPharmacy] = useState<Pharmacy | null>(null);

	const [fields, setFields] = useState({
		city: "",
		postalCode: "",
		name: "",
		state: ""
	});

	const [isSearched, setIsSearched] = useState(false);

	const [pharmacySearchError, setPharmacySearchError] = useState<boolean>(false);

	const [currentPage, setCurrentPage] = useState(1);
	const [isMobile, setIsMobile] = useState<boolean | null>(null);
	const [mode, setMode] = useState("");

	// State tracking variables
	const [consent, setConsent] = useState<boolean>();
	const [patientName, setPatientName] = useState<string | undefined>("");
	const [patientEmail, setPatientEmail] = useState<string>("");
	const [maxStep, setMaxStep] = useState(0);
	const [formValues, setFormValues] = useState<FormValue[] | undefined>([]);

	const [redirectUri, setRedirectUri] = useState<string>("");
	const [emptyFieldError, setEmptyFieldError] = useState<boolean>(false);

	const fetchPharmacies = useCallback(
		async (config: any) => {
			try {
				setLoading(true);
				const response = await getPharmacies(config);
				if (response && response.Value.length) {
					setPharmacyList(Pharmacy.parsePharmacies(response.Value));
				} else {
					setLoading(false);
					setPharmacySearchError(true);
				}
				setLoading(false);
				setIsSearched(true);
			} catch (e) {
				setLoading(false);
			}
		},
		[getPharmacies]
	);

	useEffect(() => {
		const navState = state as FormGeneratorProps;
		setFields({
			city: navState?.patientAddress?.city || "",
			postalCode: navState?.patientAddress?.postalCode || "",
			name: "",
			state: navState?.patientAddress?.state || ""
		});
		//First search with patient address when it mounts.
		const config = {
			url: searchPharmacyUrl,
			body: new SearchPharmacyRequest( //Api fails with empty strings but not with null values, so if it's empty, we send null.
				navState?.patientAddress?.city === "" ? null : navState?.patientAddress?.city,
				navState?.patientAddress?.postalCode === "" ? null : Number(navState?.patientAddress?.postalCode),
				null,
				navState?.patientAddress?.state === "" ? null : navState?.patientAddress?.state
			),
			method: HTTP_METHODS.POST,
			headers: FETCH_HEADERS
		};
		fetchPharmacies(config);
	}, [fetchPharmacies, searchPharmacyUrl, state]);

	const pharmacyChangeHandler = (selectedPharmacy: Pharmacy) => {
		pharmacyList.forEach((pharmacy: Pharmacy) => {
			if (pharmacy.id === selectedPharmacy.id) {
				setSelectedPharmacy(pharmacy);
			}
		});
	};

	const paginationChangeHandler = (event: any, page: number) => {
		setCurrentPage(page);
	};

	const navigateTo = (path: string) => {
		const queryParams = constructQueryParams([redirectUri, mode]);
		const currentState: any = {
			formValues: formValues,
			consent: consent,
			pharmacy: selectedPharmacy,
			maxStep: maxStep,
			origin: "pharmacy",
			patientName,
			patientEmail
		};

		navigate(`/${zipCode}/consult/${consultId}/${path}${queryParams}`, {
			state: currentState
		});
	};

	const handleSubmit = () => {
		if (modeParam && modeParam === "demo") {
			if (selectedPharmacy?.id && selectedPharmacy.id !== undefined) {
				navigateTo("summary");
			}
			return;
		}
		const parsedAnswer = (answer: any) => {
			if (Array.isArray(answer)) {
				return answer.join(", ");
			}
			return answer.toString();
		};

		const questionItem = formValues?.map((question) => {
			const finalValue = question.type === "date" ? format(question.value, "MM-dd-yyyy") : question.value;
			return {
				linkId: question.key,
				text: question.text,
				answer: parsedAnswer(finalValue)
			};
		});

		if (selectedPharmacy?.id && selectedPharmacy.id !== undefined) {
			const payloadObj = new QuestionnaireAnswersPayload(
				consultId,
				CONSTANTS.QUESTIONNAIRE_REPONSE,
				FORM_RESPONSE_STATUS.IN_PROGRESS,
				selectedPharmacy.id,
				questionItem,
				null
			);

			// Make POST to GGM's API
			postRequest(payloadObj);
		}
	};

	const postRequest = async (payload: QuestionnaireAnswersPayload) => {
		setLoading(true);

		const config = {
			url: QuestionnaireService.getFinalizeQuestionnaireUrl(),
			method: HTTP_METHODS.POST,
			body: payload,
			headers: FETCH_HEADERS
		} as RequestConfig;

		const response = await sendRequest(config);
		setLoading(false);

		if (response && response.success) {
			navigate(`/${zipCode}/consult/success`, { state: { pharmacy: selectedPharmacy } });
		} else if (response && !response.success) {
			navigate(`/${zipCode}/consult/rejection`, { state: response.errors });
		} else {
			navigate(`/${zipCode}/consult/rejection`, {
				state: ["Server error, please try again soon"]
			});
		}
	};

	const searchPharmacy = useCallback(() => {
		if (!Object.values(fields).some((field) => field.trim() !== "")) {
			setEmptyFieldError(true);
			return;
		}

		const config = {
			url: searchPharmacyUrl,
			body: new SearchPharmacyRequest(
				fields.city === "" ? null : fields.city,
				fields.postalCode === "" ? null : Number(fields.postalCode),
				fields.name === "" ? null : fields.name,
				fields.state === "" ? null : fields.state
			),
			method: HTTP_METHODS.POST,
			headers: FETCH_HEADERS
		};

		fetchPharmacies(config);
	}, [fields, searchPharmacyUrl, fetchPharmacies]);

	useEffect(() => {
		if (state) {
			const { consent, formValues, maxStep, patientName, patientEmail } = state as FormGeneratorProps;
			setConsent(consent || false);
			setFormValues(formValues);
			setMaxStep(maxStep || formValues.length);
			setPatientEmail(patientEmail || "");
			setPatientName(patientName);
		}

		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) {
			setRedirectUri(`redirect_uri=${redirectUri}`);
		}

		if (modeParam) {
			setMode(`mode=${modeParam}`);
		}
	}, [state, setRedirectUri, queryParams, isSearched, modeParam]);

	useEffect(() => {
		if (state) {
			const { consent, formValues, maxStep } = state as FormGeneratorProps;
			setConsent(consent || false);
			setFormValues(formValues);
			setMaxStep(maxStep || formValues.length);
		}
	}, [state]);

	useEffect(() => {
		updateProgress(EventType.STEP_CHANGE, consultId, INTAKE_STEP.PHARMACY_PICKER_PAGE, sendRequest);
	}, [consultId, sendRequest]);

	const handleWindowSizeChange = () => {
		if (isBrowser) {
			if (window.innerWidth <= 700) {
				setIsMobile(true);
			} else {
				setIsMobile(false);
			}
		}
	};

	useEffect(() => {
		if (setExitPoint) setExitPoint("pharmacy_picker_page");
		handleWindowSizeChange();
		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const filterPage = (element: any, index: number) => {
		const from = (currentPage - 1) * ITEMS_PER_PAGE;
		const to = ITEMS_PER_PAGE * currentPage - 1;
		return index >= from && index <= to;
	};

	const setFieldsValues = (e: any) => {
		setFields((prev) => {
			return {
				...prev,
				[e.target.name]: e.target.value
			};
		});
	};

	return (
		<PageWrapper enabled={true} id="pharmacy-picker-page-wrapper">
			<Backdrop open={loading} sx={{ zIndex: 11 }} data-testid="loader">
				<CircularProgress sx={{ color: "#FFF" }} />
			</Backdrop>
			<h2>{intl.formatMessage({ id: "pharmacyPickerTitle" })}</h2>
			<span>{intl.formatMessage({ id: "pharmacyPickerSubtitle" })}</span>
			<div className="mb-1 mt-2">
				<TextField
					sx={{ width: 1 }}
					id="pharmacy-name"
					name="name"
					value={fields.name}
					label={intl.formatMessage({ id: "pharmacyNameLabel" })}
					onChange={setFieldsValues}
					inputProps={{ "data-testid": "pharmacy-name" }}
					autoComplete="false"
					data-testid={"text-input-parent-pharmacy-name"}
				/>
			</div>
			<div className="search-parameter-container">
				<div>
					<FormControl sx={{ minWidth: 120, width: 1 }}>
						<InputLabel id="state-label">{intl.formatMessage({ id: "stateLabel" })}</InputLabel>
						<Select
							labelId="state-label"
							id="state"
							name="state"
							value={fields.state}
							label={intl.formatMessage({ id: "stateLabel" })}
							onChange={setFieldsValues}
						>
							<MenuItem value={""}>{intl.formatMessage({ id: "noneOption" })}</MenuItem>
							{STATES.map((s: any) => {
								return (
									<MenuItem key={s.value} value={s.value}>
										{s.label}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>
				</div>
				<div>
					<TextField
						sx={{ width: 1 }}
						id="city"
						name="city"
						value={fields.city}
						label={intl.formatMessage({ id: "cityLabel" })}
						onChange={setFieldsValues}
						inputProps={{ "data-testid": "city" }}
						autoComplete="false"
						data-testid={"text-input-parent-city"}
					/>
				</div>
				<div>
					<TextField
						sx={{ width: 1 }}
						id="zipCode"
						value={fields.postalCode}
						name="postalCode"
						label={intl.formatMessage({ id: "zipCodeLabel" })}
						onChange={setFieldsValues}
						inputProps={{ "data-testid": "zipCode" }}
						autoComplete="false"
						data-testid={"text-input-parent-zipCode"}
					/>
				</div>
			</div>

			{emptyFieldError ? (
				<Alert severity="warning" sx={{ my: 4 }}>
					{intl.formatMessage({ id: "pharmacyFillingError" })}
				</Alert>
			) : (
				<></>
			)}
			<DefaultButton
				buttonTitle={intl.formatMessage({ id: "searchPharmacies" })}
				onClick={searchPharmacy}
				style={{ width: 1, mt: "2rem" }}
				disabled={!Object.values(fields).some((field) => field.trim() !== "")}
			/>

			<div className="centered-col-flex-container">
				{error !== "" || (pharmacyList.length === 0 && isSearched && loading) || pharmacySearchError ? (
					<Alert severity="warning" sx={{ my: 4 }}>
						{intl.formatMessage({ id: "pharmacySearchError" })}
					</Alert>
				) : (
					pharmacyList.filter(filterPage).map((pharma: Pharmacy) => {
						return (
							<PharmacyOption
								key={pharma.id}
								pharmacy={pharma}
								onChangeHandler={pharmacyChangeHandler}
								active={selectedPharmacy?.id === pharma.id}
							/>
						);
					})
				)}
			</div>

			<div className="pagination-container">
				{pharmacyList.length ? (
					<Pagination
						count={Math.ceil(pharmacyList.length / ITEMS_PER_PAGE)}
						onChange={paginationChangeHandler}
						size={isMobile ? "large" : "small"}
					/>
				) : (
					<></>
				)}
			</div>
			<div className="form-nav-button-container">
				<Button
					id="prev"
					variant="outlined"
					className="form-nav-button left"
					onClick={() => navigateTo("start/questionnaire")}
					data-testid={"prev"}
				>
					<ChevronLeft fontSize="inherit" />
					{intl.formatMessage({ id: "previousButton" })}
				</Button>
				<Button
					id="submit"
					variant="outlined"
					className="form-nav-button right"
					onClick={handleSubmit}
					data-testid={"submit"}
					disabled={!selectedPharmacy}
				>
					{/* Once this changes are fully implements, this button will only display Continue */}
					{modeParam === "demo" ? "Continue" : "Submit"}
					<ChevronRight fontSize="inherit" />
				</Button>
			</div>
		</PageWrapper>
	);
};

export default PharmacyPickerPage;
