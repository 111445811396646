export class SearchPharmacyRequest {
	city?: string | null;
	zip?: number | string | null;
	name: string | null;
	state?: string | null;
	constructor(
		city: string | null | undefined,
		zip: number | string | null | undefined,
		name: string | null,
		state: string | null | undefined
	) {
		this.city = city;
		this.zip = zip;
		this.name = name;
		this.state = state;
	}
}
