import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FormValue } from "../../domain/FormValue";
import { format } from "date-fns";
import { useIntl } from "react-intl";
import { LocalPharmacyOutlined, AccountCircleOutlined, ViewListOutlined } from "@mui/icons-material";
import { Box, Paper } from "@mui/material";
import { SUMMARY_BUTTON, BOX_STYLING, CENTER_FLEXBOX_STYLING, LEFT_FLEXBOX_STYLING } from "../../utility/styles";
import { LoadingButton } from "@mui/lab";

import { SuccessPageAddress } from "../../domain/types/SuccessPageAddres";
import { QuestionnaireAnswersPayload } from "../../domain/QuestionnaireAnswersPayload";
import { QuestionnaireService } from "../../api/questionnaire-service";
import { CONSTANTS, FETCH_HEADERS, HTTP_METHODS, PROCEDURE } from "../../utility/constants";
import { Pharmacy } from "../../domain/Pharmacy";
import { constructQueryParams } from "../../utility/stringUtils";
import { FORM_RESPONSE_STATUS } from "../../domain/types/FormElement";
import useHttp, { RequestConfig } from "../../hooks/use-http/use-http";

import PageWrapper from "../../components/page-wrapper/page-wrapper";

import "./SummaryPage.css";

export type ConsultationSummaryData = {
	formValues: FormValue[];
	consent: boolean;
	privacy: boolean;
	pharmacy?: Pharmacy;
	maxStep: number;
	address?: SuccessPageAddress;
	patientName: string;
	patientEmail: string | undefined;
};

const ConsultationSummary = () => {
	const navigate = useNavigate();
	const { state } = useLocation();
	const intl = useIntl();
	const { formValues, privacy, pharmacy, maxStep, address, patientName, patientEmail } =
		state as ConsultationSummaryData;
	const { data, sendRequest } = useHttp();
	const { consultId, zipCode } = useParams();
	const [loading, setLoading] = useState<boolean>(false);
	const [queryParams] = useSearchParams();
	const [redirectUri, setRedirectUri] = useState<string>("");
	const [procedure, setProcedure] = useState<string>("");
	const [mode, setMode] = useState("");
	const redirectUriParam = queryParams.get("redirect_uri");
	const procedureParam = queryParams.get("procedure");
	const modeParam = queryParams.get("mode");

	const treatmentType =
		procedureParam === PROCEDURE.VPH
			? `VPH ${intl.formatMessage({ id: "intake" })}`
			: procedureParam === PROCEDURE.UTI
			? `UTI ${intl.formatMessage({ id: "intake" })}`
			: `Covid ${intl.formatMessage({ id: "intake" })}`;

	useEffect(() => {
		if (data && data.status === 200) {
			navigate("/consult/success");
		}
	}, [data, navigate]);

	useEffect(() => {
		window.scrollTo(0, 0);
		if (redirectUriParam) {
			setRedirectUri(`redirect_uri=${redirectUriParam}`);
		}

		if (procedureParam) {
			setProcedure(`procedure=${procedureParam}`);
		}

		if (modeParam) {
			setMode(`mode=${modeParam}`);
		}
	}, [modeParam, procedureParam, redirectUriParam]);

	const submitHandler = () => {
		const params = constructQueryParams([redirectUri, procedure, mode]);
		const currentState = {
			address,
			pharmacy
		};

		if (modeParam && modeParam === "demo") {
			navigate(`/${zipCode}/consult/success${params}`, { state: currentState });
			return;
		}

		const parsedAnswer = (answer: any) => {
			if (Array.isArray(answer)) {
				return answer.join(", ");
			}
			return answer.toString();
		};

		const questionItem = formValues?.map((question) => {
			const finalValue = question.type === "date" ? format(question.value, "MM-dd-yyyy") : question.value;
			return {
				linkId: question.key,
				text: question.text,
				answer: parsedAnswer(finalValue)
			};
		});

		const parsedAddress = () => {
			return [
				{
					line: [address?.addressLine1 || "", address?.addressLine2 || ""],
					city: address?.city || "",
					state: address?.state || "",
					postalCode: address?.postalCode || ""
				}
			];
		};

		const pharmacyId = pharmacy && pharmacy.id ? pharmacy.id : null;
		const patientAddress = parsedAddress();

		const payloadObj = new QuestionnaireAnswersPayload(
			consultId,
			CONSTANTS.QUESTIONNAIRE_REPONSE,
			FORM_RESPONSE_STATUS.IN_PROGRESS,
			pharmacyId,
			questionItem,
			patientAddress
		);

		// // Make POST to GGM's API
		postRequest(payloadObj);
	};

	const postRequest = async (payload: QuestionnaireAnswersPayload) => {
		setLoading(true);

		const config = {
			url: QuestionnaireService.getFinalizeQuestionnaireUrl(),
			method: HTTP_METHODS.POST,
			body: payload,
			headers: FETCH_HEADERS
		} as RequestConfig;

		const response = await sendRequest(config);
		setLoading(false);

		if (response && response.success) {
			navigate(`/${zipCode}/consult/success`, { state: { pharmacy: pharmacy, address } });
		} else if (response && !response.success) {
			navigate(`/${zipCode}/consult/rejection`, { state: response.errors });
		} else {
			navigate(`/${zipCode}/consult/rejection`, {
				state: ["Server error, please try again soon"]
			});
		}
	};

	const navigateTo = (path: String) => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		const currentState = {
			privacy: privacy,
			consultId: consultId,
			formValues: formValues,
			maxStep: maxStep,
			pharmacy: pharmacy
		};
		navigate(`/${zipCode}/consult/${consultId}/${path}${queryParams}`, {
			state: currentState
		});
	};

	return (
		<>
			<PageWrapper id="summary-page" enabled>
				<div className="summary-page" data-testid="summary-html">
					<Box sx={BOX_STYLING} className="summary-page-content">
						<Paper elevation={0} className="summary-container">
							{pharmacy && (
								<>
									<h2 className="page-title">{intl.formatMessage({ id: "pharmacyTitle" })}</h2>
									<Box sx={CENTER_FLEXBOX_STYLING} className="mt-2">
										<h3>{intl.formatMessage({ id: "pharmacyPreferredPharmacy" })}</h3>
										<span className="edit-icon" onClick={() => navigateTo("pharmacy")}></span>
									</Box>
									<Box sx={LEFT_FLEXBOX_STYLING} className="text-icons-container selected-pharmacy">
										<span className="health-icon">
											<LocalPharmacyOutlined />
										</span>
										<div>
											<h3>{pharmacy.name}</h3>
											<p className="ligh-text">
												{pharmacy.address1}, {pharmacy.city}, {pharmacy.state}{" "}
												{pharmacy.zip_code}
											</p>
										</div>
									</Box>
								</>
							)}

							{address && (
								<div className="address-container">
									<h2 className="page-title">{intl.formatMessage({ id: "summaryAddressTitle" })}</h2>
									<span className="status-badge">
										{intl.formatMessage({ id: "summaryStatusLabel" })}
									</span>
									<div className="h-flex-row space-between">
										<h4 className="header mb-1">
											{intl.formatMessage({ id: "summaryPageSubtitle" })}
										</h4>
										<span className="edit-icon" onClick={() => navigateTo("shipping")}></span>
									</div>

									<div className="address-lines">
										<p>{address.addressLine1}</p>
										<p>{address.addressLine2}</p>
										<p>
											{address.city}, {address.state} {address.postalCode}
										</p>
									</div>
								</div>
							)}
						</Paper>
					</Box>
					<div className="summary-information-page mt-2">
						<div className="h-flex-row">
							<h4 className="header mb-1">
								{intl.formatMessage({ id: "summaryclientInformationTitle" })}
							</h4>
						</div>

						<div className="h-flex-row ph-1 mt-1">
							<div className="h-flex-row full-width">
								<AccountCircleOutlined />
								<div className="h-flex-row space-between full-width ml-1">
									<div className="v-flex-col">
										{patientName && <h4 className="m-0">{patientName}</h4>}

										<div className="ligh-text">{patientEmail && patientEmail}</div>
									</div>
								</div>
							</div>
						</div>

						<div className="h-flex-row ph-1 mt-2">
							<div className="h-flex-row full-width">
								<ViewListOutlined />
								<div className="h-flex-row space-between full-width ml-1">
									<div className="v-flex-col">
										<h4 className="m-0">{treatmentType}</h4>
										<p className="ligh-text" data-testid="values-length">
											{formValues.length} {intl.formatMessage({ id: "summaryQuestions" })}
										</p>
									</div>
									<div
										className="edit-icon float-right"
										onClick={() => navigateTo("start/questionnaire")}
									></div>
								</div>
							</div>
						</div>
					</div>

					<div className="form-nav-button-container mt-1">
						<LoadingButton
							loading={loading}
							onClick={submitHandler}
							variant="contained"
							className="form-nav-button"
							sx={SUMMARY_BUTTON}
						>
							{loading ? "" : "Submit"}
						</LoadingButton>
					</div>
				</div>
			</PageWrapper>
		</>
	);
};

export default ConsultationSummary;
