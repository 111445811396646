import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { STATES } from "../../../utility/constants";

type AddressState = {
	addressLine1: string;
	addressLine2: string;
	city: string;
	state: string;
	postalCode: string;
};

type AddressInputProps = {
	patientAddress?: AddressState;
	setAddressValues: (arg: any) => void;
	requiredField: boolean;
};

const AddressInput = (props: AddressInputProps) => {
	const { requiredField, setAddressValues, patientAddress } = props;
	const [address, setAddress] = useState({
		addressLine1: patientAddress?.addressLine1 || "",
		addressLine2: patientAddress?.addressLine2 || "",
		city: patientAddress?.city || "",
		state: patientAddress?.state || "",
		postalCode: patientAddress?.postalCode || ""
	});
	const onChangeHandler = (event: any) => {
		setAddress({
			...address,
			[event.target.name]: event.target.value
		});
		setAddressValues(event);
	};

	useEffect(() => {
		if (patientAddress) {
			setAddress(patientAddress);
		}
	}, [patientAddress]);

	return (
		<>
			<div className="mt-2">
				<TextField
					sx={{ width: 1 }}
					id="addressLine1"
					name="addressLine1"
					label="Street Address"
					value={address.addressLine1}
					onChange={onChangeHandler}
					inputProps={{ "data-testid": "addressLine1" }}
					autoComplete="false"
					data-testid={"text-input-parent-addressLine1"}
					required={requiredField}
				/>
			</div>
			<div className="mt-2">
				<TextField
					sx={{ width: 1 }}
					id="addressLine2"
					name="addressLine2"
					label="Suite/Apt #"
					value={address.addressLine2}
					onChange={onChangeHandler}
					inputProps={{ "data-testid": "addressLine2" }}
					autoComplete="false"
					data-testid={"text-input-parent-addressLine2"}
				/>
			</div>
			<div className="mt-2">
				<TextField
					sx={{ width: 1 }}
					id="city"
					name="city"
					label="City"
					value={address.city}
					onChange={onChangeHandler}
					inputProps={{ "data-testid": "city" }}
					autoComplete="false"
					data-testid={"text-input-parent-city"}
					required={requiredField}
				/>
			</div>
			<div className="mt-2">
				<FormControl sx={{ minWidth: 120, width: 1 }}>
					<InputLabel id="state-label">State</InputLabel>
					<Select
						labelId="state-label"
						id="state"
						name="state"
						value={address.state}
						label="State"
						onChange={onChangeHandler}
						required={requiredField}
					>
						{STATES.map((s: any) => {
							return (
								<MenuItem key={s.value} value={s.value}>
									{s.label}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</div>
			<div className="mt-2">
				<TextField
					sx={{ width: 1 }}
					id="postalCode"
					name="postalCode"
					label="Zip Code"
					value={address.postalCode}
					onChange={onChangeHandler}
					inputProps={{ "data-testid": "postalCode" }}
					autoComplete="false"
					data-testid={"text-input-parent-postalCode"}
					required={requiredField}
				/>
			</div>
		</>
	);
};

export default AddressInput;
