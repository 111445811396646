import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { CONSTANTS } from "../../../utility/constants";
import { useEffect, useState } from "react";

// SVG in top box with arrow pointing up
const UploadSVG = () => {
	return (
		<svg
			width="96"
			height="96"
			viewBox="0 0 96 96"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="upload-svg"
		>
			<circle cx="48" cy="48" r="40" fill="#F2F2F2" />
			<circle cx="48" cy="48" r="20" fill="#D1D1D1" />
			<path
				d="M47 56C47 56.5523 47.4477 57 48 57C48.5523 57 49 56.5523 49 56L47 56ZM48.7071 39.2929C48.3166 38.9024 47.6834 38.9024 47.2929 39.2929L40.9289 45.6569C40.5384 46.0474 40.5384 46.6805 40.9289 47.0711C41.3195 47.4616 41.9526 47.4616 42.3431 47.0711L48 41.4142L53.6569 47.0711C54.0474 47.4616 54.6805 47.4616 55.0711 47.0711C55.4616 46.6805 55.4616 46.0474 55.0711 45.6569L48.7071 39.2929ZM49 56L49 40L47 40L47 56L49 56Z"
				fill="#F8F8F8"
			/>
		</svg>
	);
};

// SVG displaying when a file is uploaded without issues
const FileSVG = () => {
	return (
		<svg
			width="20"
			height="26"
			viewBox="0 0 20 26"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="file-svg"
		>
			<path
				d="M12.6667 1V6.33333C12.6667 6.68696 12.8072 7.02609 13.0573 7.27614C13.3073 7.52619 13.6465 7.66667 14.0001 7.66667H19.3334M12.6667 1H3.33341C2.62617 1 1.94789 1.28095 1.4478 1.78105C0.9477 2.28115 0.666748 2.95942 0.666748 3.66667V22.3333C0.666748 23.0406 0.9477 23.7189 1.4478 24.219C1.94789 24.719 2.62617 25 3.33341 25H16.6667C17.374 25 18.0523 24.719 18.5524 24.219C19.0525 23.7189 19.3334 23.0406 19.3334 22.3333V7.66667M12.6667 1L19.3334 7.66667"
				stroke="#999999"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

// SVG used as a button to delete the uploaded file
const DeleteSVG = () => {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="delete-svg"
		>
			<path
				d="M1 17L9 9M9 9L17 1M9 9L1 1.00002M9 9L17 17"
				stroke="black"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

// SVG shown when the file is too large, red exclamation point
const ErrorSVG = () => {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			data-testid="error-svg"
		>
			<circle cx="16" cy="16.0003" r="12.7333" stroke="#D7373F" strokeWidth="1.2" />
			<path
				d="M16 22.7051V22.7184"
				stroke="#D7373F"
				strokeWidth="1.2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M16 11V18" stroke="#D7373F" strokeWidth="1.2" strokeLinecap="round" />
		</svg>
	);
};

// props for the attachment input component
type AttachmentInputProps = {
	item: any;
	onChangeHandler(fieldId: string, text: string, type: string, value: any): any;
	enabled: boolean;
	value?: any;
};

const AttachmentInput = (props: AttachmentInputProps) => {
	// Basing this off of the Wheel media endpoint until GGM flushes out their requirements for the endpoint they are building
	const ALLOWED_FILE_TYPES = "image/bmp, image/jpeg, image/png, application/pdf";

	// the file that is currently uploaded by the browser or prepoulated
	const [uploadedFile, setUploadedFile] = useState<File | undefined>(undefined);
	// the size of the file in bytes as a string
	const [fileSize, setFileSize] = useState<String>("");
	// the name of the uploaded file
	const [uploadedFileName, setUploadedFileName] = useState<String>("");
	// set to true if the file exceeds MAX_FILE_UPLOAD_SIZE
	const [fileTooLarge, setFileTooLarge] = useState<Boolean>(false);
	// used to display the spinning wheel when the file is uploading
	const [processingFile, setProcessingFile] = useState<Boolean>(true);

	const {
		item: { linkId, text, type, required }
	} = props;

	// if the file is already selected by the user when navigating to this question the file is prepopulated
	useEffect(() => {
		if (props.value !== null && props.value !== undefined && props.value !== "") {
			handleFileInput(props.value);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// ran when the file is prepopulated or the user has selected a file and it is finished uploading
	const handleFileInput = (file: File) => {
		// set to true to show spinning circle showing file is processing, this is not complete until the file is processed by the FileReader
		setProcessingFile(true);
		setUploadedFile(file);
		setUploadedFileName(file!.name);
		// convert file to MB or KB string depending on size
		setFileSize(
			file!.size > 1000000
				? `${(file!.size * 0.000001).toString().split(".")[0]}MB`
				: `${(file!.size * 0.001).toString().split(".")[0]}KB`
		);
		const reader = new FileReader();
		// reader.addEventListener("load", (event) => {
		// const result = event!.target!.result;
		// Do something with result, perhaps return it to be stored in the core session once we have the media upload endpoint design flushed out with GGM
		// });
		reader.addEventListener("progress", (event) => {
			if (event.loaded === event.total) {
				setProcessingFile(false);
			}
		});
		reader.readAsDataURL(file);
		// if file is too large don't let the user advance to the next page by sending the file as an empty string
		// 25MB was discussed by GGMe in working meeting
		// if other providers have a different size requirement or it changes based on input type (image, pdf, audio, video)
		// then we will need additional logic to handle those dynamic size requirements, perhaps passing this in as a prop
		if (file!.size > CONSTANTS.MAX_FILE_UPLOAD_SIZE) {
			setFileTooLarge(true);
			props.onChangeHandler(linkId, text, type, "");
		} else {
			props.onChangeHandler(linkId, text, type, file);
		}
	};

	// clear file selection when X button is clicked on the file
	const deleteFile = () => {
		setUploadedFile(undefined);
		setFileSize("");
		setUploadedFileName("");
		setFileTooLarge(false);
		setProcessingFile(false);
		props.onChangeHandler(linkId, text, type, "");
	};

	return (
		<div className={props.enabled ? "mb-2" : "hidden"}>
			{/* Top upload box with dashed border */}
			<Box
				sx={{
					mt: 4,
					mb: 1,
					width: 1,
					p: 2,
					border: "1px dashed grey",
					bgcolor: "#F8F8F8",
					borderRadius: "12px",
					display: "flex",
					flexDirection: "column",
					alignItems: "center"
				}}
			>
				<Container
					sx={{
						textAlign: "center"
					}}
				>
					<UploadSVG />
				</Container>
				<Button
					data-testid="browse-button"
					variant="contained"
					component="label"
					sx={{
						backgroundColor: uploadedFile ? "#B3B3B3" : "#1860F0",
						borderRadius: "5px",
						fontWeight: "600",
						width: 0.5
					}}
					disabled={!!uploadedFileName}
				>
					{CONSTANTS.BROWSE}
					<input
						data-testid="file-input"
						required={required}
						hidden
						accept={ALLOWED_FILE_TYPES}
						multiple
						type="file"
						onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
							handleFileInput(event?.target?.files![0])
						}
						onClick={(event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
							const element = event.target as HTMLInputElement;
							element.value = "";
						}}
						disabled={!!uploadedFileName}
					/>
				</Button>
				<Typography variant="subtitle2" sx={{ mt: 1, color: "#999999", fontFamily: "PoppinsRegular" }}>
					{CONSTANTS.MAXIMUM_FILE_SIZE} {CONSTANTS.MAX_FILE_UPLOAD_SIZE * 0.000001} {CONSTANTS.MEGABYTE}
				</Typography>
			</Box>
			{/* Bottom file box showing file upload status, file name, file size, error if file is too large */}
			{uploadedFile ? (
				<Box
					sx={{
						mt: 1,
						mb: 1,
						width: 1,
						p: 2,
						border: `1px solid ${fileTooLarge ? "#D7373F" : "#070707"}`,
						borderRadius: "8px",
						display: "flex",
						flexDirection: "row"
					}}
				>
					<div
						style={{ display: "flex", alignItems: "center", paddingRight: processingFile ? "5px" : "0px" }}
					>
						{processingFile ? (
							<CircularProgress
								data-testid="circular-progress"
								thickness={2}
								size={26}
								sx={{ display: "flex", color: "#000000" }}
							/>
						) : fileTooLarge ? (
							<ErrorSVG />
						) : (
							<FileSVG />
						)}
					</div>
					<Box sx={{ display: "flex", flexDirection: "column", width: 0.75 }}>
						<Typography
							variant="body2"
							sx={{
								ml: 1,
								color: "#000000",
								fontFamily: "PoppinsRegular",
								textOverflow: "ellipsis",
								whiteSpace: "nowrap",
								overflow: "hidden"
							}}
						>
							{uploadedFileName}
						</Typography>
						<Typography
							sx={{
								ml: 1,
								color: "#999999",
								fontFamily: "PoppinsRegular",
								fontSize: ".75rem",
								lineHeight: 1
							}}
						>
							{fileSize}
						</Typography>
					</Box>
					<div
						style={{ marginLeft: "auto", display: "flex", alignItems: "center", cursor: "pointer" }}
						onClick={deleteFile}
					>
						<DeleteSVG />
					</div>
				</Box>
			) : null}
			{fileTooLarge ? (
				<Typography sx={{ color: "#D7373F", fontSize: ".6rem", fontFamily: "PoppinsRegular", mb: 2 }}>
					{CONSTANTS.THIS_FILE_IS_TOO_BIG} {CONSTANTS.MAX_FILE_UPLOAD_SIZE * 0.000001} {CONSTANTS.MEGABYTE}
				</Typography>
			) : null}
		</div>
	);
};

export default AttachmentInput;
