import { Address } from "./PatientAddress";

export class QuestionnaireAnswersPayload {
	id: string | undefined;
	resourceType: string;
	status: string;
	pharmacyID: string | null;
	item: any;
	address: Address[] | null;
	constructor(
		id: string | undefined,
		resourceType: string,
		status: string,
		pharmacyID: string | null,
		item: any,
		address: Address[] | null
	) {
		this.id = id;
		this.resourceType = resourceType;
		this.status = status;
		this.pharmacyID = pharmacyID;
		this.item = item;
		this.address = address;
	}
}
