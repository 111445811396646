import { useEffect } from "react";
import { Pharmacy } from "../../domain/Pharmacy";
import { LocalPharmacyOutlined } from "@mui/icons-material";

import "./pharmacy-option.css";

type PharmacyOptionProps = {
	pharmacy: Pharmacy;
	active: boolean;
	onChangeHandler: (pharmacy: Pharmacy) => void;
};

const mapIconStyling = {
	p: 1,
	borderRadius: 100,
	backgroundColor: "#F6F6F7",
	color: "black"
};

const PharmacyOption = (props: PharmacyOptionProps) => {
	const { pharmacy, active, onChangeHandler } = props;
	const isBrowser = typeof window !== "undefined";

	useEffect(() => {
		if (isBrowser) {
			window.scrollTo(0, 0);
		}
	}, [isBrowser]);
	const radioMark = (active: boolean) => {
		return (
			<div className={active ? "radio-checks radio-btn selected" : "radio-checks radio-btn"}>
				<div />
			</div>
		);
	};
	return (
		<div
			data-testid={`pharmacy-${pharmacy.id}`}
			aria-label="pharmacy-item"
			className={"pharmacy-container " + (active ? "active" : "")}
			onClick={() => onChangeHandler(pharmacy)}
			id={`pharmacy-${pharmacy.id}`}
		>
			<div className="centered-col-flex-container icon">
				<LocalPharmacyOutlined sx={mapIconStyling} />
			</div>
			<div className="centered-col-flex-container address">
				<h4>{pharmacy.name}</h4>
				<div className="full-address">{Pharmacy.getFullAddress(pharmacy)}</div>
			</div>
			<div>{radioMark(active)}</div>
		</div>
	);
};

export default PharmacyOption;
