import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ExitPointProvider } from "../context/exit-point";
import ConsentPage from "../pages/ConsentPage/ConsentPage";
import DenialPage from "../pages/DenialPage/DenialPage";
import FormViewerPage from "../pages/FormViewerPage/FormViewerPage";
import PharmacyPickerPage from "../pages/PharmacyPickerPage/PharmacyPickerPage";
import ShippingAddressPage from "../pages/ShippingAddressPage/shipping-address-page";
import SuccessPage from "../pages/SuccessPage/SuccessPage";
import ConsultationSummary from "../pages/SummaryPage/SummaryPage";
import { PATH_PARAMS } from "../utility/constants";
import { detectBrowserLanguage } from "../utility/detect-browser-language";
import { AppLocale } from "../utility/lang";
import { datadogLogs } from "@datadog/browser-logs";

const T2TRouter = () => {
	const lang = detectBrowserLanguage();
	const currentAppLocale = AppLocale[lang];

	useEffect(() => {
		initDatadog();
	}, []);

	const initDatadog = (): void => {
		const clientToken = process.env.REACT_APP_DATADOG_CLIENT_TOKEN;

		if (!clientToken) {
			console.error("Datadog Client Token is missing");
			return;
		}

		datadogLogs.init({
			clientToken: clientToken,
			site: "datadoghq.com",
			forwardErrorsToLogs: true,
			sampleRate: Number(process.env.REACT_APP_DATADOG_REPLAY_PERCENT),
			silentMultipleInit: true,
			env: process.env.NODE_ENV, //extended NODE_ENV for more environments names in global.d.ts.
			service: "t2t-partner-web",
			version: process.env.DD_VERSION
		});
	};

	return (
		<IntlProvider messages={currentAppLocale} locale={lang}>
			<ExitPointProvider>
				<BrowserRouter>
					<Routes>
						<Route
							path={PATH_PARAMS.ROOT_APP_PATH}
							element={
								<React.StrictMode>
									<ConsentPage />
								</React.StrictMode>
							}
						/>
						<Route
							path={PATH_PARAMS.CONSULTATION_PATH}
							element={
								<React.StrictMode>
									<FormViewerPage />
								</React.StrictMode>
							}
						/>
						<Route
							path={PATH_PARAMS.SHIPPING_ADDRESS_PATH}
							element={
								<React.StrictMode>
									<ShippingAddressPage />
								</React.StrictMode>
							}
						/>
						<Route
							path={PATH_PARAMS.PHARMACY_PICKER_PATH}
							element={
								<React.StrictMode>
									<PharmacyPickerPage />
								</React.StrictMode>
							}
						/>
						<Route
							path={PATH_PARAMS.SUCCESS_PAGE_PATH}
							element={
								<React.StrictMode>
									<SuccessPage />
								</React.StrictMode>
							}
						/>
						<Route
							path={PATH_PARAMS.DENIAL_PAGE_PATH}
							element={
								<React.StrictMode>
									<DenialPage />
								</React.StrictMode>
							}
						/>
						<Route
							path={PATH_PARAMS.SUMMARY_PAGE}
							element={
								<React.StrictMode>
									<ConsultationSummary />
								</React.StrictMode>
							}
						/>
					</Routes>
				</BrowserRouter>
			</ExitPointProvider>
		</IntlProvider>
	);
};

export default T2TRouter;
