export class Pharmacy {
	id: string;
	name: string;
	primary_phone: string;
	address1: string;
	address2: string;
	city: string;
	state: string;
	zip_code: number;

	constructor(
		id: string,
		name: string,
		primary_phone: string,
		address1: string,
		address2: string,
		city: string,
		state: string,
		zip_code: number
	) {
		this.id = id;
		this.name = name;
		this.primary_phone = primary_phone;
		this.address1 = address1;
		this.address2 = address2;
		this.city = city;
		this.state = state;
		this.zip_code = zip_code;
	}

	static parsePharmacies = (pharmaciesJson: any[]): Pharmacy[] => {
		const pharmacies: Pharmacy[] = [];
		for (const pharmacy of pharmaciesJson) {
			pharmacies.push(Pharmacy.parsePharmacy(pharmacy));
		}
		return pharmacies;
	};

	static parsePharmacy = (pharmacyJson: any) => {
		return new Pharmacy(
			pharmacyJson.id,
			pharmacyJson.name,
			pharmacyJson.primary_phone,
			pharmacyJson.address1,
			pharmacyJson.address2,
			pharmacyJson.city,
			pharmacyJson.state,
			pharmacyJson.zip_code
		);
	};

	static getFullAddress = (pharmacy: Pharmacy | undefined): string => {
		if (pharmacy) {
			if (pharmacy.address2) {
				return `${pharmacy.address1} ${pharmacy.address2} ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zip_code}`;
			} else {
				return `${pharmacy.address1} ${pharmacy.city}, ${pharmacy.state} ${pharmacy.zip_code}`;
			}
		} else {
			return "";
		}
	};
}
