import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { Alert, Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { constructQueryParams } from "../../utility/stringUtils";
import { questionnaireUrl } from "../../utility/questionnaireEndpoint";
import { FormValue } from "../../domain/FormValue";
import { FormGeneratorProps } from "../../components/form-components/form-generator/form-generator";

import PageWrapper from "../../components/page-wrapper/page-wrapper";
import AddressInput from "../../components/form-components/address-input/address-input";

const ShippingAddressPage = () => {
	const intl = useIntl();
	const navigate = useNavigate();
	const { state } = useLocation();
	const { consultId, zipCode } = useParams();
	const [queryParams] = useSearchParams();
	const [procedure, setProcedure] = useState<string>("");
	const [redirectUri, setRedirectUri] = useState<string>("");
	const [mode, setMode] = useState("");
	const [address, setAddress] = useState({
		addressLine1: "",
		addressLine2: "",
		city: "",
		state: "",
		postalCode: ""
	});
	const [patientName, setPatientName] = useState<string | undefined>("");
	const [patientEmail, setPatientEmail] = useState<string>("");
	const [addressError, setAddressError] = useState<string>("");
	// State tracking variables
	const [consent, setConsent] = useState<boolean>();
	const [maxStep, setMaxStep] = useState(0);
	const [formValues, setFormValues] = useState<FormValue[] | undefined>([]);

	const origin = "shipping";

	const navigateTo = (path: string) => {
		const queryParams = constructQueryParams([procedure, redirectUri, mode]);
		const currentState = {
			formValues: formValues,
			consent: consent,
			pharmacy: null,
			maxStep: maxStep,
			origin: origin,
			address: address,
			patientName,
			patientEmail
		};

		if (path === "review") {
			if (address.addressLine1 && address.city && address.state && address.postalCode) {
				navigate(`/${zipCode}/consult/${consultId}/summary${queryParams}`, { state: currentState });
			} else {
				setAddressError("Please fill out all required fields");
			}
		}

		if (path === "questionnaire") {
			navigate(`/${zipCode}/consult/${consultId}/start/questionnaire${queryParams}`, {
				state: currentState
			});
		}
	};

	useEffect(() => {
		const procedureParam = queryParams.get("procedure") as keyof typeof questionnaireUrl;
		if (procedureParam) setProcedure(`procedure=${procedureParam}`);

		const redirectUri = queryParams.get("redirect_uri");
		if (redirectUri) setRedirectUri(`redirect_uri=${redirectUri}`);

		const modeParam = queryParams.get("mode");
		if (modeParam) setMode(`mode=${modeParam}`);
	}, [queryParams]);

	useEffect(() => {
		if (state) {
			const { consent, formValues, maxStep, patientAddress, patientEmail, patientName } =
				state as FormGeneratorProps;
			setConsent(consent || false);
			setFormValues(formValues);
			setMaxStep(maxStep || formValues.length);
			setPatientName(patientName);
			setPatientEmail(patientEmail || "");
			if (patientAddress !== undefined) {
				setAddress({
					addressLine1: patientAddress.line[0],
					addressLine2: patientAddress.line[1] || "",
					city: patientAddress.city,
					state: patientAddress.state,
					postalCode: patientAddress.postalCode
				});
			}
		}
	}, [state]);

	const setAddressValues = (e: any) => {
		setAddress({
			...address,
			[e.target.name]: e.target.value
		});
	};

	return (
		<PageWrapper enabled={true} id="shipping-address-page-wrapper">
			<h2>{intl.formatMessage({ id: "shippingTitle" })}</h2>
			<div className="mt-1">
				<AddressInput patientAddress={address} setAddressValues={setAddressValues} requiredField={true} />
			</div>

			<div className="mt-2">
				{addressError && (
					<Alert severity="warning" sx={{ mb: 4 }}>
						{addressError}
					</Alert>
				)}
			</div>

			<div className="form-nav-button-container mt-2">
				<Button
					id="prev"
					variant="outlined"
					className="form-nav-button left"
					onClick={() => navigateTo("questionnaire")}
					data-testid={"prev"}
				>
					<ChevronLeft fontSize="inherit" />
					{intl.formatMessage({ id: "previousButton" })}
				</Button>
				<Button
					id="navToReview"
					variant="outlined"
					className="form-nav-button right"
					onClick={() => navigateTo("review")}
					data-testid={"navToReview"}
				>
					{intl.formatMessage({ id: "continueButton" })}
					<ChevronRight fontSize="inherit" />
				</Button>
			</div>
		</PageWrapper>
	);
};

export default ShippingAddressPage;
