import { useCallback, useEffect, useState } from "react";
import { ChevronRight, ChevronLeft } from "@mui/icons-material";
import { Button, Alert } from "@mui/material";
import { FORM_TYPE } from "../../../domain/types/FormElement";
import { FormValue } from "../../../domain/FormValue";
import { FormItem } from "../../../domain/FormItem";
import { compareItems, hasRequiredValue } from "../../../utility/formGenerationUtils";
import { EventType } from "../../../utility/constants";
import { Pharmacy } from "../../../domain/Pharmacy";
import { ExitPointContextType, useExitPoint } from "../../../context/exit-point";
import { useLocation } from "react-router-dom";
import { updateProgress } from "../../../utility/metricsUtils";
import { useIntl } from "react-intl";
import { Address } from "../../../domain/PatientAddress";

import useHttp from "../../../hooks/use-http/use-http";
import TextDisplay from "../text-display/text-display";
import TextInput from "../text-input/text-input";
import DateInput from "../date-input/date-input";
import SelectInput from "../select-input/select-input";
import YesNoInput from "../yes-no-input/yes-no-input";
import HeightInput from "../height-input/height-input";
import AttachmentInput from "../attachment-input/attachment-input";

import "./form-generator.css";

// Props that comes from injecting into JSX
export type FormGeneratorProps = {
	formItems: FormItem[];
	consent: boolean | undefined;
	consultId: string | undefined;
	formValues: FormValue[];
	nextPage: (state: any) => void;
	prevPage: (state: any) => void;
	pharmacy?: Pharmacy;
	patientAddress?: Address;
	maxStep?: number;
	origin?: string;
	patientName?: string;
	patientEmail?: string | undefined;
};

type StateObject = {
	formValues: FormValue[];
	consent: boolean | undefined;
	privacy: boolean | undefined;
	pharmacy: Pharmacy;
	patientAddress?: Address;
	formId: string;
	maxStep?: number;
	origin?: string;
};

type NavigationState = {
	state: StateObject;
};

const FormGenerator = (props: FormGeneratorProps) => {
	const { setExitPoint } = useExitPoint() as ExitPointContextType;
	const intl = useIntl();
	const { state } = useLocation() as NavigationState;
	const { consent, formItems, consultId } = props;
	const { sendRequest } = useHttp();

	const [formValues, setFormValues] = useState<FormValue[]>([]);
	const [inputHTMLElements, setInputHTMLElements] = useState<JSX.Element[]>([]);
	const [currentStep, setCurrentStep] = useState(0); // Index
	const [MAX_STEPS, setMaxSteps] = useState(0); // Index
	const [displayError, setDisplayError] = useState(false);

	useEffect(() => {
		const exitPoint = formItems[currentStep]?.linkId;
		if (setExitPoint) setExitPoint(exitPoint);
	}, [currentStep, formItems, setExitPoint]);

	useEffect(() => {
		if (state && state.maxStep && (state.origin === "pharmacy" || state.origin === "shipping")) {
			setCurrentStep(state.maxStep);
		}
	}, [state]);

	const nextStep = (index: number) => {
		let canAdvance = hasRequiredValue(inputHTMLElements[index], formValues);
		if (canAdvance && currentStep < MAX_STEPS) {
			setCurrentStep((prev) => prev + 1);
			setDisplayError(false);

			const inputId = formItems[index + 1].linkId;
			updateProgress(EventType.STEP_CHANGE, consultId, inputId, sendRequest);
		} else if (canAdvance && currentStep === MAX_STEPS) {
			const state = {
				consent: consent,
				formValues: formValues,
				maxStep: MAX_STEPS,
				patientAddress: props.patientAddress
			};
			props.nextPage(state);
		} else {
			setDisplayError(true);
		}
	};

	const prevStep = () => {
		if (currentStep === 0) {
			const state = {
				consent: consent,
				formValues: formValues
			};
			props.prevPage(state);
		}
		if (currentStep > 0) {
			setCurrentStep((prev) => prev - 1);

			if (currentStep - 1 !== 0) {
				const inputId = formItems[currentStep - 1].linkId;
				updateProgress(EventType.STEP_CHANGE, consultId, inputId, sendRequest);
			}
		}
	};

	const getFormValue = useCallback(
		(id: string) => {
			const item = formValues.find((formValue: FormValue) => formValue.key === id);
			if (item) {
				return item.value;
			}

			return null;
		},
		[formValues]
	);

	/**
	 * This method helps determine whether the input should be enabled or not by checking all dependent input values and validates
	 * them against the `enableWhen` criteria if it exists on the current input.
	 */
	const isInputEnabled = useCallback(
		(formItem: FormItem) => {
			if (formItem.enableWhen !== undefined) {
				// Check if the dependent input has the proper value set
				const enableCondition = formItem.enableWhen[0];
				const dependentInput = formValues.find((fv: FormValue) => fv.key === enableCondition?.question);

				if (enableCondition !== undefined && dependentInput !== undefined) {
					const expectedValue = formItem?.enableWhen[0]?.getExpectedAnswer();
					let isExpectedValue = compareItems(dependentInput.value, enableCondition.operator, expectedValue);
					if (isExpectedValue) {
						// The value set is to what we're expecting so the input SHOULD be enabled.
						return true;
					} else {
						return false;
					}
				} else {
					// The dependent value is not set so input SHOULD NOT be enabled.
					return false;
				}
			}

			// The form item does not have a dependency so it SHOULD be enabled.
			return true;
		},
		[formValues]
	);

	/**
	 * This method detects any formValue changes that happen in all nested child components and updates the current (main/parent)
	 * component's list of values
	 */
	const onChangeHandler = useCallback(
		(fieldId: string, text: string, type: string, value: any) => {
			let updatedValues = [...formValues];

			if (updatedValues.find((formValue: FormValue) => formValue.key === fieldId)) {
				for (const updatedValue of updatedValues) {
					if (updatedValue.key === fieldId) {
						updatedValue.value = value;
						break;
					}
				}
			} else {
				updatedValues.push(new FormValue(fieldId, text, type, value));
			}

			updatedValues.sort((a, b) => (a.key < b.key ? -1 : a.key > b.key ? 1 : 0));
			setDisplayError(false);
			setFormValues([...updatedValues]);
		},
		[formValues]
	);

	const getInputType = useCallback(
		(formItem: FormItem): JSX.Element | null => {
			const enabled = isInputEnabled(formItem);
			const value = getFormValue(formItem.linkId);

			switch (formItem.type) {
				case FORM_TYPE.DISPLAY.valueOf():
					return <TextDisplay key={formItem.linkId} item={formItem} enabled={enabled} />;
				case FORM_TYPE.TEXT.valueOf():
					return (
						<TextInput
							key={formItem.linkId}
							item={formItem}
							onChangeHandler={onChangeHandler}
							enabled={enabled}
							value={value}
						/>
					);
				case FORM_TYPE.BOOLEAN.valueOf():
					return (
						<YesNoInput
							key={formItem.linkId}
							item={formItem}
							onChangeHandler={onChangeHandler}
							enabled={enabled}
							value={value}
						/>
					);
				case FORM_TYPE.DATE.valueOf():
					return (
						<DateInput
							key={formItem.linkId}
							item={formItem}
							onChangeHandler={onChangeHandler}
							enabled={enabled}
							value={value}
						/>
					);
				case FORM_TYPE.CHOICE.valueOf():
					return (
						<SelectInput
							key={formItem.linkId}
							item={formItem}
							onChangeHandler={onChangeHandler}
							enabled={enabled}
							value={value}
						/>
					);
				case FORM_TYPE.HEIGHT.valueOf():
					return (
						<HeightInput
							key={formItem.linkId}
							item={formItem}
							onChangeHandler={onChangeHandler}
							enabled={enabled}
							value={value}
						/>
					);
				case FORM_TYPE.ATTACHMENT.valueOf():
					return (
						<AttachmentInput
							key={formItem.linkId}
							item={formItem}
							onChangeHandler={onChangeHandler}
							enabled={enabled}
							value={value}
						/>
					);
				default:
					return null;
			}
		},
		[getFormValue, isInputEnabled, onChangeHandler]
	);

	const prefillForm = useCallback(() => {
		if (props.formValues !== undefined && props.formValues !== null && props.formValues.length > 0) {
			setFormValues(props.formValues);
		}
	}, [props.formValues]);

	/**
	 * This method will get the corresponding JSX elements to display in each card of the questionnaire
	 */
	const getHTMLElements = useCallback((): JSX.Element[] => {
		const elements: JSX.Element[] = [];
		formItems.forEach((item: FormItem, idx) => {
			if (item.text.includes("your height")) {
				item.type = "height";
			}
			const element = getInputType(item);
			if (element) {
				elements.push(element);
			}
		});

		// Filter out all elements that aren't enabled
		return elements.filter((element: JSX.Element) => element.props.enabled);
	}, [formItems, getInputType]);

	useEffect(() => {
		let html: JSX.Element[] = getHTMLElements();
		setInputHTMLElements(html);
		setMaxSteps(html.length - 1);
	}, [getHTMLElements, formValues]);

	useEffect(() => {
		prefillForm();
	}, [prefillForm]);

	useEffect(() => {
		if (formItems && formItems.length > 0 && currentStep === 0) {
			const inputId = formItems[currentStep]?.linkId;
			updateProgress(EventType.STEP_CHANGE, consultId, inputId, sendRequest);
		}
	}, [consultId, sendRequest, formItems, currentStep]);

	return (
		<form id="form" data-testid="form" className="form">
			{inputHTMLElements.map((element: JSX.Element, index: number) => {
				return (
					<div key={index} data-testid={`card-${index}`}>
						{currentStep === index && (
							<div className={currentStep !== index ? "hidden" : ""}>
								{element}

								{displayError && (
									<Alert severity="warning" sx={{ mb: 3 }}>
										{intl.formatMessage({ id: "missingReqFieldsError" })}
									</Alert>
								)}

								<div className="form-nav-button-container">
									<Button
										variant="outlined"
										className="form-nav-button left"
										onClick={prevStep}
										id={"prevStep-" + index}
										data-testid={"prevStep-" + index}
									>
										<ChevronLeft fontSize="inherit" />
										{intl.formatMessage({ id: "previousButton" })}
									</Button>
									<Button
										variant="outlined"
										className={
											!displayError ? "form-nav-button right" : "form-nav-button right disabled"
										}
										onClick={() => {
											nextStep(index);
										}}
										id={"nextStep-" + index}
										data-testid={"nextStep-" + index}
									>
										{intl.formatMessage({ id: "continueButton" })}
										<ChevronRight fontSize="inherit" />
									</Button>
								</div>
							</div>
						)}
					</div>
				);
			})}
		</form>
	);
};

export default FormGenerator;
