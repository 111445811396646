export const enLang = {
	continueButton: "Continue",
	previousButton: "Previous",
	submitButton: "Submit",
	headerExit: "EXIT",
	yes: "Yes",
	no: "No",
	consentPageTitle: "We know this is a dificult time and we are here to help.",
	consentPageSubtitle:
		"Next, you will answer some questions that our clinician partners will evaluate to get you treatment, if eligible",
	consentStepText11: "Answer",
	consentStepText12: "Questions",
	consentStepText21: "Evaluation",
	consentStepText22: "& Prescription",
	consentStepText31: "Pharmacy",
	consentStepText32: "RX Dispensing",
	consentCheckboxLabel: "I agree to the ",
	consentCheckboxLink: "Subscription Service Terms and Conditions of Use",
	consentDisclaimer:
		"By clicking Continue, you attest that you are not experiencing any of the following: high fever (>101.9F/38.8C), shortness of breath, difficulty breathing, chest pain, heart palpitations, abnormal bruising, abnormal bleeding, extreme fatigue, dizziness, new weakness or paralysis, difficulty with speech, confusion, extreme pain in any body part, or inability to remain hydrated or keep down fluids. If you are experiencing any of the above or feel you may have any other life-threatening condition, please go to the emergency department or call 911 immediately.",
	covidExitDialogTitle: "COVID-19 CAN BE A SERIOUS ILLNESS",
	utiVphExitDialogTitle: "Are you sure you don't want free treatment?",
	exitDialogContent:
		"You are only a few clicks away from seeing if you qualify for free treatment. You can lose access if you exit now.",
	exitDialogLink: "I'm not interested",
	covidExitDialogLink: "I'm not interested in free treatment",
	exitDialogButton: "Get Free Treatment",
	pharmacySearchError: "No pharmacies that carry Paxlovid match the specified criteria",
	searchPharmacyTitle: "Search Pharmacy",
	searchByAddress: "Search By Address",
	pharmacyFillingError: "Please provide at least one input to search for pharmacies",
	pharmacySelectionError: "Please choose a pharmacy of your preference to continue",
	searchPharmacies: "Search Pharmacies",
	AddressInputLabel: "Address or Zip Code",
	searchDistanceLabel: "Search distance (in miles)",
	pharmacyNameLabel: "Pharmacy name",
	phoneOrFax: "Phone or Fax",
	address: "Address",
	pharmacyPickerTitle: "Select A Pharmacy",
	pharmacyPickerSubtitle:
		"Choose where you'd like to pick up your medication if your healthcare professional writes you a perscription.",
	successPageTitle: "Your Information Has Been Submitted",
	successPageDescription:
		"One of our licensed clinicians will review the submitted information and suggest a treatment plan that is best for you. You will receive an email shortly with next steps.",
	successPagePharmacyTitle: "Preferred Pharmacy",
	successPagePharmacySubtitle: "Your prescriptions will be sent here.",
	stepperOne: "Diagnostics",
	stepperTwo: "Telehealth consult",
	stepperThree: "Clinician review",
	stepperFour: "Prescription",
	denialTitle: "Based on your answers, we are not able to offer you treatment at this time.",
	denialDescription:
		"We do suggest that you consider further evaluation in person with your doctor, at an urgent care center, or other care location.",
	denialMotives: "We came to this determination because:",
	denialHelp: "NEED HELP?",
	denialHelpText:
		"Get help on various topics and frequently asked question from adjustments to medication to account-related issues",
	denialHelpButton: "Get Help",
	shippingTitle: "Please provide your shipping address",
	missingReqFieldsError: "Please fill out all required fields",
	streetAddressLabel: "Street Address",
	suiteAptLabel: "Suite/Apt #",
	cityLabel: "City",
	stateLabel: "State",
	zipCodeLabel: "Zip Code",
	noneOption: "None",
	summaryAddressTitle: "Visit summary",
	summaryStatusLabel: "Completed",
	summaryPageSubtitle: "Shipping information",
	summaryclientInformationTitle: "YOUR INFORMATION",
	summaryClientName: "Linda López",
	summaryClientMail: "linda.lopez@gmail.com",
	summaryQuestions: "questions",
	summarySubmit: "Submit",
	pharmacyTitle: "Review and submit",
	pharmacyPreferredPharmacy: "Preferred Pharmacy",
	intake: "Intake",
	successPrescriptionTitle: "Your prescriptions will be sent here.",
	successTypeShippingTitle: "Shipping information",
	successTypePharmacyTitle: "Preferred Pharmacy",
	pharmacyPreferenceDelivery: "Ship directly to me (arrival within 1 to 2 days)",
	pharmacyPreferenceQuestion: "How would you like to receive your prescription?"
};
